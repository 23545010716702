import * as React from 'react';

import {
  Container,
  PostContainer,
  Header,
  ImageContainer,
  PostContent,
  ShareContainer,
} from './styles';

import { convertDate } from '@/utils/convertDate';
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share';
import { SITE_URL } from '@/config';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { FaFacebook, FaLinkedin, FaWhatsapp } from 'react-icons/fa';

interface IImage {
  id: string;
  imageFile: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

interface IPost {
  title: string;
  slug: string;
  date: string;
  excerpt: string;
  highlight: boolean;
  content: string;
  author: string;
  categories: any;
  banner: IImage;
}

interface IProps {
  post: IPost;
}

const MainSection: React.FC<IProps> = ({ post }) => {
  return (
    <Container>
      <PostContainer>
        <Header>
          <h1>{post.title}</h1>
          <div>
            <span className="category">{post.categories[0].name}</span>
            <span>{post.author}</span>
            <span>{convertDate(post.date)}</span>
          </div>
        </Header>
        <ImageContainer>
          <GatsbyImage
            image={post.banner.imageFile.childImageSharp.gatsbyImageData}
            alt="imagem do blog"
          />
        </ImageContainer>
        <PostContent>
          <div dangerouslySetInnerHTML={{ __html: post.content }} />
        </PostContent>
        <ShareContainer>
          <span>Compartilhe:</span>
          <div>
            <FacebookShareButton
              url={`${SITE_URL}/blog/${post.slug}`}
              quote={`Veja o post ${post.title} no Blog da Generoso 📰\n\n`}
              windowWidth={1366}
              windowHeight={768}
            >
              <FaFacebook size={29} />
            </FacebookShareButton>
            <LinkedinShareButton
              url={`${SITE_URL}/blog/${post.slug}`}
              title={`Veja o post ${post.title} no Blog da Generoso 📰\n\n`}
              windowWidth={1366}
              windowHeight={768}
            >
              <FaLinkedin size={29} />
            </LinkedinShareButton>
            <WhatsappShareButton
              url={`${SITE_URL}/blog/${post.slug}`}
              title={`Veja o post ${post.title} no Blog da Generoso 📰\n`}
              separator={'\n'}
              windowWidth={1366}
              windowHeight={768}
            >
              <FaWhatsapp size={29} />
            </WhatsappShareButton>
          </div>
        </ShareContainer>
      </PostContainer>
    </Container>
  );
};

export default MainSection;
