import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const PageWrapper = styled.div`
  width: 100%;
  max-width: 100vw;

  height: 100%;

  display: flex;
  justify-content: center;
  background: #f7f6f4;
`;

export const PageContainer = styled.div`
  width: 100%;
  max-width: 1920px;

  height: fit-content;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding-top: 30px;

  .back {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 0 16px;

    width: 100%;
    max-width: 1280px;

    a:nth-child(2) {
      font-size: 18px;
      color: ${colors.gray600}; //todo color
      line-height: 28px;

      &:hover {
        color: ${colors.blue500};
        transition: 0.15s;
      }
    }

    a:first-child {
      padding: 10px;
      background: #e4e6eb;
      border-radius: 50%;
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: #e1e1e3;
        transition: 0.15s;
      }
    }

    @media (min-width: 1300px) {
      padding: 0;
    }
  }
`;

export const NewsLetterWrapper = styled.div`
  margin-top: 100px;
  width: 100%;
`;
