import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  max-width: 976px;

  padding: 45px 0;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  border-radius: 6px;

  display: flex;
  justify-content: center;

  margin-top: 16px;

  margin-bottom: 64px;

  @media (max-width: 800px) {
    padding: 0;
    margin-top: 24px;
    box-shadow: none;
    border-radius: 0;
  }
`;

export const PostContainer = styled.div`
  width: 100%;
  max-width: 768px;
`;

export const Header = styled.header`
  width: 100%;

  h1 {
    font-size: 48px;
    line-height: 48px;
    color: ${colors.gray600};
  }

  div {
    display: flex;
    gap: 20px;
    margin-top: 5px;

    flex-wrap: wrap;
    row-gap: 10px;

    span {
      font-size: 16px;
      line-height: 20px;
      color: #6a6a6a;
    }

    span.category {
      text-transform: uppercase;
      color: ${colors.blue500};
    }
  }

  @media (max-width: 800px) {
    padding: 0 16px;

    h1 {
      font-size: 32px;
      line-height: 40px;
    }
  }
`;

export const ImageContainer = styled.div`
  border-radius: 6px;
  max-height: 384px;
  width: 100%;
  margin-top: 28px;
  overflow: hidden;

  .gatsby-image-wrapper {
    max-height: 380px;
    max-width: 770px;
  }

  @media (max-width: 800px) {
    border-radius: 0;
  }
`;

export const PostContent = styled.div`
  width: 100%;

  margin-top: 28px;

  p,
  b,
  strong,
  div,
  span,
  li {
    font-size: 16px;
    line-height: 20px;
    color: #6a6a6a;

    :not(:first-child) {
      margin-top: 16px;
    }
  }

  @media (max-width: 800px) {
    padding: 0 16px;
  }
`;

export const ShareContainer = styled.div`
  padding-top: 48px;
  margin-top: 48px;

  display: flex;

  border-top: 1px solid #707070;
  align-items: center;

  img {
    height: 36px;
  }

  span {
    font-size: 16px;
    color: #606060;
  }

  div {
    display: flex;
    gap: 16px;
    margin-left: 28px;
  }

  @media (max-width: 800px) {
    margin: 0 16px;
    margin-top: 48px;

    flex-direction: column;
    align-items: center;

    div {
      margin-left: 0;
      margin-top: 20px;
    }
  }
`;
