import * as React from 'react';
import Layout from '@/components/Layout';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import MainSection from './MainSection';
import { PageContainer, PageWrapper } from './styles';
import SEO from '@/components/SEO';

interface IImage {
  id: string;
  imageFile: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

interface IProps {
  pageContext: {
    title: string;
    slug: string;
    date: string;
    excerpt: string;
    highlight: boolean;
    content: string;
    author: any;
    categories: any;
    banner: IImage;
    keywords: any;
  };
}

const Post: React.FC<IProps> = ({ pageContext }): JSX.Element => {
  return (
    <>
      <SEO
        title={pageContext.title}
        keywords={pageContext.keywords}
        description={pageContext.excerpt}
        image={'/og-512.png'}
      />
      <Layout>
        <PageWrapper>
          <PageContainer>
            <MainSection post={pageContext} />
          </PageContainer>
        </PageWrapper>
      </Layout>
    </>
  );
};

export default Post;
